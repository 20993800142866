import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const HeaderStyle = styled.div`
  background-color: #fff;
  width: 100%;
`

const HeaderTextStyle = styled.h1`
  padding-left: 0.25rem;
  font-size: 4rem;
  font-style: italic;
  margin: 0;
  background: linear-gradient(450deg, #7ee8fa 0%, #80ff72 74%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 5rem;
`

const Header = ({ siteTitle }) => (
  <HeaderStyle>
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 0`,
        }}
      >
        <HeaderTextStyle>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </HeaderTextStyle>
      </div>
    </header>
  </HeaderStyle>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
